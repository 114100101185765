
import { defineComponent } from "vue";
import ProductionForm from "@/components/Production/ProductionForm.vue";
import { mapActions } from "vuex";

export default defineComponent({
  components: {
    ProductionForm,
  },
  async created() {
    await this.fetchFdict("WO");
  },
  methods: {
    ...mapActions({
      fetchFdict: "fdict/fetchFdict",
    }),
  },
});
