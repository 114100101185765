import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "col-12 p-0 border-noround border-none mt-3 m-0" }
const _hoisted_3 = { class: "menutab-item" }
const _hoisted_4 = { class: "col-12 p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TabView, {
        scrollable: true,
        class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden",
        activeIndex: _ctx.items.findIndex((item) => item.to === _ctx.$route.path)
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (tab) => {
            return (_openBlock(), _createBlock(_component_TabPanel, {
              key: tab.to
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_router_link, {
                    to: tab.to,
                    class: "menutab-item-link"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Button, {
                        label: tab.label,
                        class: "menutab-button"
                      }, null, 8, ["label"])
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["activeIndex"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ])
  ]))
}