
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    Button,
  },
  computed: {
    items(): { label: string; to: string }[] {
      return [
        {
          label: "Scheduling",
          to: "/production/scheduling",
        },
        {
          label: "Work Order",
          to: "/production/work-order",
        },
      ];
    },
  },
});
